import React from "react";
import { TeliaIcon } from "@telia/b2x-telia-icon";

import styles from "./b2x-filter-chip.module.scss";

type Props = {
  id: string;
  name: string;
  text: string;
  value: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  icon?: { name: string; svg: string };
};
export const FilterChip = ({
  id,
  name,
  text,
  value,
  checked,
  disabled = false,
  icon,
  onChange,
}: Props) => {
  return (
    <div className={styles["b2x-filter-chip"]}>
      <div className={styles["b2x-filter-chip__wrapper"]}>
        <input
          data-testid={`filter-chip-input-${id}`}
          id={id}
          className={styles["b2x-filter-chip__input"]}
          type="checkbox"
          name={name}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
        <label
          data-testid={`filter-chip-label-${id}`}
          className={styles["b2x-filter-chip__label"]}
          htmlFor={id}
        >
          {text}
          {icon ? (
            <TeliaIcon
              dataTestid={`filter-chip-icon-${id}`}
              svg={icon.svg}
              className={styles["b2x-filter-chip__icon"]}
            />
          ) : null}
        </label>
      </div>
    </div>
  );
};
